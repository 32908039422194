import { VueWizard } from '@/vue-wizard';
import { Component, Prop } from 'vue-property-decorator';
import AdminDetails from '@/app/components/agents-management/dedicated-components/admin-details/AdminDetails.vue';
import { store } from '@/app/store';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
//require('/src/assets/css/style.css');
@Component({
  name: 'admin-navbar',
  components: { AdminDetails, PerfectScrollbar },
})
export default class AdminNavbar extends VueWizard {
  public user: any;
  @Prop()
  public navKey!: any;
  public collapsed = false;
  created(): void {
    this.user = store.state.user;
  }

  mounted(): void {
    $('#sidebar-menu a').on('click', function (e) {
      if ($(this).parent().hasClass('submenu')) {
        e.preventDefault();
      }
      if (!$(this).hasClass('subdrop')) {
        $('ul', $(this).parents('ul:first')).slideUp(350);
        $('a', $(this).parents('ul:first')).removeClass('subdrop');
        $(this).next('ul').slideDown(350);
        $(this).addClass('subdrop');
      } else if ($(this).hasClass('subdrop')) {
        $(this).removeClass('subdrop');
        $(this).next('ul').slideUp(350);
      }
    });
    $('#sidebar-menu ul li.submenu a.active')
      .parents('li:last')
      .children('a:first')
      .addClass('active')
      .trigger('click');
  }
}
