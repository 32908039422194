import { Component } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR } from '@/config';
import { store } from '@/app/store';
import { defineComponent } from 'vue';
import AgentsIntegration from '@/app/components/llamadas/agentes/Agentes.vue';
import EtiquetaLlamada from '@/app/components/llamadas/etiquetas/Etiquetas.vue';
import AdminNavbar from '@/app/components/admin-navbar/AdminNavbar.vue';
import NavBar from '@/app/components/shared/nav-bar/nav-bar';
import AgentsAdmin from '@/app/components/llamadas/admin/Admin.vue';
@Component({
  name: 'integrations',
  components: {
    AdminNavbar,
    AgentsIntegration,
    EtiquetaLlamada,
    NavBar,
    AgentsAdmin,
  },
})
export default class Integrations extends VueWizard {
  // state Modal
  public visible = false;

  showModal() {
    this.visible = true;
  }

  handleOk() {
    this.visible = false;
  }
}
