import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR, COUNTRIES } from '@/config';
import { store } from '@/app/store';
import AdminNavbar from '../../admin-navbar/admin-navbar';
import axios from 'axios';

interface Tag {
  name: string;
  description: string;
  _id: string;
}
@Component({
  name: 'etiqueta-llamada',
  components: {
    AdminNavbar,
  },
})
export default class EtiquetaLlamada extends Vue {
  @Prop() public name!: string;
  @Prop() public description!: string;

  public mode_modal: string = 'Crear Etiqueta';

  public columns: any = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      scopedSlots: { customRender: 'name' },
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      scopedSlots: { customRender: 'description' },
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      scopedSlots: { customRender: 'actions' },
    },
  ];

  public tagsEnable: any = [];
  public tagsDisable: any = [];
  //   Modal
  public visible: boolean = false;
  public form = {
    name: '',
    description: '',
  };

  private id: string = '';

  public isEditing: boolean = false;
  public confirmLoading: boolean = false;
  async mounted() {
    await this.getTagsEnable();
    await this.getTagsDisable();
    if (this.name && this.description) {
      this.mode_modal = 'Editar Etiqueta';
      this.form.name = this.name;
      this.form.description = this.description;
      this.isEditing = true;
    }
  }
  showModal() {
    this.visible = true;
  }

  openModal() {
    this.mode_modal = 'Crear Etiqueta';
    this.form.name = '';
    this.form.description = '';
    this.id = '';
    this.isEditing = false;
    this.showModal();
  }

  async submitForm() {
    const { name, description } = this.form;
    const currentProject = this.$store.getters.project;
    this.confirmLoading = true;
    const resquest = axios.post(`${BASE_URL_MANAGER}/tags`, {
      name,
      description,
      project: currentProject._id,
    });

    await resquest;

    this.visible = false;
    this.confirmLoading = false;
    await this.getTagsEnable();
  }

  async submitFormEdit() {
    const { name, description } = this.form;
    this.confirmLoading = true;
    const resquest = axios.put(`${BASE_URL_MANAGER}/tags/${this.id}`, {
      name,
      description,
    });

    await resquest;

    this.visible = false;
    this.confirmLoading = false;
    await this.getTagsEnable();
  }

  handleCancel() {
    this.visible = false;
  }

  editTag(item: Tag) {
    this.isEditing = true;
    this.form.name = item.name;
    this.form.description = item.description;
    this.mode_modal = 'Editar Etiqueta';
    this.id = item._id;
    this.showModal();
  }

  async archiveTag(item: Tag) {
    const resquest = axios.put(`${BASE_URL_MANAGER}/tags/enable/${item._id}`, {
      enable: false,
    });

    await resquest;

    await this.getTagsEnable();
    await this.getTagsDisable();
  }
  async reactivateTag(item: Tag) {
    const resquest = axios.put(`${BASE_URL_MANAGER}/tags/enable/${item._id}`, {
      enable: true,
    });

    await resquest;

    await this.getTagsEnable();
    await this.getTagsDisable();
  }

  async getTagsEnable() {
    const currentProject = this.$store.getters.project;
    const resquest = axios.get(
      `${BASE_URL_MANAGER}/tags/tagByProject/${currentProject._id}`,
    );
    const response = await resquest;
    this.tagsEnable = response.data;
  }
  async getTagsDisable() {
    const currentProject = this.$store.getters.project;
    const resquest = axios.get(
      `${BASE_URL_MANAGER}/tags/tagByProjectDisable/${currentProject._id}`,
    );
    const response = await resquest;
    this.tagsDisable = response.data;
  }
}
