import { Component, Watch } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR } from '@/config';
import AdminNavbar from '@/app/components/admin-navbar/AdminNavbar.vue';
import axios from 'axios';

@Component({
  name: 'agents-admin',
  components: {
    AdminNavbar,
  },
})
export default class AgentsAdmin extends VueWizard {
  // table projects props
  public columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Correo administrador',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'País',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      scopedSlots: { customRender: 'status' },
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      scopedSlots: { customRender: 'actions' },
    },
  ];

  public original_data_source = [
    {
      id: -1,
      name: '',
      email: '',
      country: '',
      status: '',
    },
  ];

  public data_source = [
    {
      id: 1,
      name: 'Proyecto 1',
      email: 'proyecto1@admin.com',
      country: 'Colombia',
      status: 'Activo',
    },
    {
      id: 2,
      name: 'Proyecto 2',
      email: 'proyecto2@admin.com',
      country: 'Colombia',
      status: 'Activo',
    },
    {
      id: 3,
      name: 'Proyecto 3',
      email: 'proyecto3@admin.com',
      country: 'Chile',
      status: 'Activo',
    },
  ];

  public searchProject = '';

  public state_selected = '';

  public states: {
    [key: number]: string;
  } = {
    0: 'Todos',
    1: 'Sin integrar',
    2: 'Pendiente primer pago',
    3: 'Pendiente activación',
    4: 'Activo',
    5: 'Por renovar',
    6: 'Desactivado',
  };

  public channel_selected: number = 1;

  public channels: {
    [key: number]: string;
  } = {
    1: 'Sin canales',
    2: '1 canal x $25.000',
    3: '2 canales x $60.000',
  };

  // transition admin
  public visible = true;
  public project_selected: {
    name: string;
    email: string;
    country: string;
    status: string;
  } = {
    name: '',
    email: '',
    country: '',
    status: '',
  };

  // llamadas
  // state llamadas salientes
  public withdraw_calls = false;
  // state llamadas entrantes
  public incoming_calls = false;
  // number of canales
  public number_channels = 0;
  // with caller id
  public with_caller_id = false;

  public cost_withdraw_calls = 0;
  public cost_incoming_calls = 20000;
  public cost_channels: {
    [key: number]: number;
  } = {
    1: 0,
    2: 25000,
    3: 60000,
  };
  public cost_caller_id = 0;

  public cost_advisers = 20000;

  public cost_total = 0;

  public visibleModalConfirm = false;

  public number_advisers = 0;

  public advisers: any[] = [];

  public tamanio_advisers = 0;

  async mounted() {
    // load agents
    await this.getAgents();
    this.updateDataSource();
  }

  handleMenuClick(e: any) {
    this.state_selected = this.states[e.key];
  }

  handleChannelClick(e: any) {
    const prev_channel_selected = this.channel_selected;
    this.channel_selected = e.key;
    this.onChannelsChange(prev_channel_selected, this.channel_selected);
  }

  updateDataSource() {
    this.original_data_source = [...this.data_source];
  }

  @Watch('searchProject')
  onSearchProjectChanged() {
    if (this.searchProject === '') {
      this.data_source = [...this.original_data_source];
      return;
    }
    // filter data_source by searchProject and state_selected values
    // seachProject is a string and can filter by name, email or country
    // state_selected is a string and can filter by status
    const data_source = this.original_data_source.filter((item: any) => {
      return (
        item.name.includes(this.searchProject) ||
        item.email.includes(this.searchProject) ||
        item.country.includes(this.searchProject)
      );
    });

    const data_source_filtered = data_source.filter((item: any) => {
      return item.status.includes(this.state_selected);
    });
    this.data_source = [...data_source_filtered];
  }
  @Watch('state_selected')
  onStateSelectedChanged() {
    if (this.state_selected === 'Todos') {
      this.data_source = [...this.original_data_source];
      return;
    }

    // filter data_source by searchProject and state_selected values
    // seachProject is a string and can filter by name, email or country
    // state_selected is a string and can filter by status
    const data_source = this.original_data_source.filter((item: any) => {
      return (
        item.name.includes(this.searchProject) ||
        item.email.includes(this.searchProject) ||
        item.country.includes(this.searchProject)
      );
    });
    const data_source_filtered = data_source.filter((item: any) => {
      return item.status.includes(this.state_selected);
    });
    this.data_source = [...data_source_filtered];
  }

  handleBack() {
    this.visible = true;
    this.project_selected = {
      name: '',
      email: '',
      country: '',
      status: '',
    };
    this.withdraw_calls = false;
    this.incoming_calls = false;
    this.number_channels = 0;
    this.with_caller_id = false;
    this.cost_total = 0;
    this.number_advisers = 0;
  }

  handleOk(record: any) {
    this.project_selected = record;

    this.visible = false;
  }

  onWithdrawChange() {
    this.withdraw_calls = !this.withdraw_calls;
    if (this.withdraw_calls) {
      this.cost_total += this.cost_withdraw_calls;
    } else {
      this.cost_total -= this.cost_withdraw_calls;
    }
  }

  // if incoming_calls is true, add cost_incoming_calls to cost_total
  // else, remove cost_incoming_calls from cost_total
  onIncomingChange() {
    this.incoming_calls = !this.incoming_calls;
    if (this.incoming_calls) {
      this.cost_total += this.cost_incoming_calls;
    } else {
      this.cost_total -= this.cost_incoming_calls;
    }
  }

  // add cost_channels to cost_total when number_channels is changed
  // number_channels is a number and can be 0, 1 or 2
  // if ch
  onChannelsChange(prev_channel_selected: number, channel_selected: number) {
    if (prev_channel_selected === channel_selected) {
      return;
    }
    this.cost_total -= this.cost_channels[prev_channel_selected];
    this.cost_total += this.cost_channels[channel_selected];
  }

  onCallerIdChange() {
    this.with_caller_id = !this.with_caller_id;
    if (this.with_caller_id) {
      this.cost_total += this.cost_caller_id;
    }
  }

  channelsText() {
    return this.channels[this.channel_selected];
  }
  @Watch('withdraw_calls')
  formatWithDrawCalls() {
    if (this.withdraw_calls) {
      return this.formattoCurrency(this.cost_withdraw_calls);
    }
    return this.formattoCurrency(0);
  }
  @Watch('incoming_calls')
  formatIncomingCalls() {
    if (this.incoming_calls) {
      return this.formattoCurrency(this.cost_incoming_calls);
    }
    return this.formattoCurrency(0);
  }
  @Watch('number_channels')
  formatChannels() {
    return this.formattoCurrency(this.cost_channels[this.channel_selected]);
  }
  @Watch('with_caller_id')
  formatCallerId() {
    if (this.with_caller_id) {
      return this.formattoCurrency(this.cost_caller_id);
    }
    return this.formattoCurrency(0);
  }

  @Watch('number_advisers')
  formatAdvisers() {
    return this.formattoCurrency(this.cost_advisers * this.number_advisers);
  }

  formattoCurrency(value: number) {
    return `$ ${value.toLocaleString()}`;
  }

  showModalConfirm() {
    this.visibleModalConfirm = true;
  }

  handleOpen() {
    this.visibleModalConfirm = false;
  }

  onAdviserChange(adviser: any) {
    adviser.assigned = !adviser.assigned;
    if (adviser.assigned) {
      this.number_advisers += 1;
      this.cost_total += this.cost_advisers;
    } else {
      this.number_advisers -= 1;
      this.cost_total -= this.cost_advisers;
    }
  }
  getNameAdviser(advisers: any) {
    return (
      ((advisers.usrage[0].name.firstName +
        ' ' +
        advisers.usrage[0].name.lastName) as String) || 'Sin nombre'
    );
  }

  getEmailAdviser(advisers: any) {
    return (advisers.usrage[0].username as String) || 'Sin correo';
  }

  // petitions
  async getAgents() {
    const user = this.$store.state.user;
    const companyId = user.user.company._id;
    const response = await axios.get(
      `${BASE_URL_MANAGER}/agent/users/relAgents/${companyId}`,
    );

    this.advisers = response.data.agents;
    this.tamanio_advisers = this.advisers.length;
  }
}
